<template>
  <v-container
    style="height: 100vh"
    fill-height
    fluid
    class="justify-center login"
    @keyup.enter="login"
  >
    <v-row justify="center" class="mt-2">
      <v-col cols="9" sm="8" md="6" lg="4" class="text-center">
        <div class="d-flex justify-center">
          <v-img
            :src="require('@/assets/logo_sou_serials_preta_01.svg')"
            max-width="300"
            class="mb-5"
          ></v-img>
        </div>
        <v-card class="fundo base mt-5" elevation="0">
          <v-form ref="loginForm" class="pt-2">
            <default-text-field
              v-model="email"
              label="Login"
              autofocus
              :rules="[rules.required]"
              :hide-details="false"
            />
            <default-text-field
              v-model="password"
              class="mt-3"
              label="Senha"
              :type="isPasswordShown ? 'text' : 'password'"
              :append-icon="isPasswordShown ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required]"
              :hide-details="false"
              @click:append="isPasswordShown = !isPasswordShown"
            />
            <v-row justify="center">
              <v-col cols="6">
                <default-button
                  class="mt-3"
                  :hide-details="false"
                  :loading="loading"
                  @click="login"
                  >Entrar</default-button
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
        <environment-toggler class="mt-5" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LoginService } from "@/services/login/LoginService";
import { UsuarioLogado } from "@/services/login/UsuarioLogado";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";
import EnvironmentToggler from "@/shared/components/vuetify/EnvironmentToggler.vue";

export default {
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      loading: false,
      isPasswordShown: false,
      rules: {
        required: (input) => !!input || "Campo obrigatório",
      },
      loginService: LoginService.build(),
    };
  },
  components: {
    DefaultButton,
    DefaultTextField,
    EnvironmentToggler,
  },
  created() {
    this.loginService = LoginService.build().setVm(this);
  },
  methods: {
    login(event) {
      if (this.isLoginFormValidated) {
        this.loginService.setEvent(event).login();
        return;
      }
      this.invalidateLoginForm();
    },
    invalidateLoginForm() {
      this.$refs.loginForm.validate(false);
    },
  },
  computed: {
    isLoginFormValidated() {
      const areAllFieldsFilled = !!this.email && !!this.password;
      return areAllFieldsFilled;
    },
  },
  beforeRouteEnter(to, from, next) {
    if (UsuarioLogado.isLogged()) {
      return next("dashboard");
    }
    next();
  },
};
</script>

<style scoped lang="scss">
.login {
  background: url("../../../public/img/BG_DESKTOP.svg") repeat;
  background-size: cover;
}

.login .fundo {
  border-radius: 16px;
  padding: 15px 30px;
  margin-top: 3rem;
}

.login .mt-10 {
  margin-top: 10px;
}

.login .v-btn {
  border-radius: 14px;
  padding: 10px 0;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
}

.login .imgLogo {
  width: 18rem;
  margin-block-end: -9rem;
}

input[type="email"] input[type="password"] {
  margin: 0 !important;
}
</style>
